import { useTranslation } from "react-i18next";
import { StreamInfoComponentProps } from "./stream-info.properties";

import './stream-info.styles.scss';
import { use } from "i18next";
import { useEffect } from "react";

export function StreamInfoComponent(properties: StreamInfoComponentProps) {
  const { t } = useTranslation();

  // video settings
  const videoSettings = properties.stream.getVideoTracks().length > 0 ? properties.stream.getVideoTracks()[0].getSettings() : undefined;

  // audio settings
  const audioSettings = properties.stream.getAudioTracks().length > 0 ? properties.stream.getAudioTracks()[0].getSettings() : undefined;

  // stream settings
  const streamSettings: MediaStreamTrack = properties.stream.getVideoTracks().length > 0 ? properties.stream.getVideoTracks()[0] : properties.stream.getAudioTracks()[0];

  // render option
  const option = (name: string, icon: string, value: boolean | undefined | string | number, description?: string,) => {
    if (typeof value === 'string') {

        // split value
        if (value.length > 30) {

            value = value.substring(0, 15) + '...'

        }

    }
    return <div className="option">
        <i className={icon}></i>
        <span>{name} {description !== undefined && <b>{description} </b>}</span>
        <b>
            {
              value === undefined && <i className='las la-times'></i>
            }
            {
              typeof value === 'boolean' && value
                ? <i className='las la-check'></i>
                : typeof value === 'boolean' && !value
                  ? <i className='las la-times'></i>
                  : value
            }
        </b>
    </div>
  }

  return <div className="StreamInfoComponent">
    <div className="stream-info Modal Right">
      <div className="Header">
        {t('settings.stream-info.title')}
        <i className="las la-times last" onClick={() => {

        properties.setShowStreamInfo()

        }} />
      </div>
      <div className="layout">
      {streamSettings && <>
        <div className='divisor'>{t('settings.stream')}</div>
        <div className="box">
          {
            option(t('settings.name'), 'las la-signature', streamSettings?.label)
          }
          {
            option(t('settings.enabled'), 'las la-plug', streamSettings?.enabled)
          }
          {
            option(t('settings.readyState'), 'las la-signal', streamSettings?.readyState)
          }
          {
            option(t('settings.kind'), 'las la-cog', streamSettings?.kind)
          }
        </div>
      </>}
      {videoSettings && <>
      <div className='divisor'>{t('settings.video')}</div>
      <div className="box">
        {
          videoSettings?.frameRate && option(t('settings.rate'), 'las la-clock', Math.round(videoSettings?.frameRate))
        }
        {
          option(t('settings.aspect'), 'las la-crop', videoSettings?.aspectRatio?.toFixed(2))
        }
        {
          option(t('settings.size'), 'las la-crop-alt', `${videoSettings?.width} x ${videoSettings?.height}`)
        }
        </div>
      </>}

      {audioSettings && <>
        <div className='divisor'>{t('settings.audio')}</div>
        <div className="box">
          {
            option(t('settings.muted'), 'las la-microphone', !streamSettings?.muted)
          }
          {
            option(t('settings.noiseSuppression'), 'las la-drum', audioSettings?.noiseSuppression)
          }
          {
            option(t('settings.sampleRate'), 'las la-clock', audioSettings?.sampleRate || 'AUTO')
          }
          {
            option(t('settings.sampleSize'), 'las la-history', audioSettings?.sampleSize)
          }
          {
            option(t('settings.echoCancellation'), 'las la-assistive-listening-systems', audioSettings?.echoCancellation)
          }
          {// @ts-ignore
            option(t('settings.latency'), 'las la-tachometer-alt', audioSettings?.latency)
          }
          {
            option(t('settings.autoGainControl'), 'las la-magic', audioSettings?.autoGainControl,)
          }
        </div>
      </>}
    </div>
    </div>
  </div>
}
