import { SelectorDevicesComponent } from "../selectorDevices/selectorDevices.component";
import { SelectorMediaComponent } from "../selectorMedia/selectorMedia.component";
import { SelectorStreamsProperties } from "./selectorStreams.properties";

import "../selectorDevices/selector.styles.scss";
import { useEffect, useState } from "react";
import { environment } from "../../../../environment";
import axios from "axios";

/**
 * Show media and devices selector in the same component to avoid multiple components
 *
 * @param props
 * @returns
 */
export default function SelectorStreams(props: SelectorStreamsProperties) {
  const [groupsData, setGroupsData] = useState<any[]>([]);
  const [hasDevicesBeenFetched, setHasDevicesBeenFetched] = useState(false);
  const [hasMediaBeenFetched, setHasMediaBeenFetched] = useState(false);
  const [canBeDisplayed, setCanBeDisplayed] = useState(false);

  useEffect(() => {
    if (hasDevicesBeenFetched && hasMediaBeenFetched) {
      setCanBeDisplayed(true);
    }
  }, [hasDevicesBeenFetched, hasMediaBeenFetched]);

  const getGroupData = async () => {
    axios.get(`${environment.api.host}/api/group/me`, {
      headers: {
          Authorization: `Bearer ${props.user.access_token}`
      }
    }).then((response) => {
      setGroupsData(response?.data?.items);
    });
  }

  useEffect(() => {
    getGroupData();

    const interval = setInterval(() => {
      getGroupData();
    }, 3000);

    return () => {
      clearInterval(interval);
    }
  }, []);


  return (
    <div className="SelectorComponent">
      <SelectorMediaComponent
        deviceInstance={props.deviceInstance}
        localScreams={props.localScreams || []}
        setScene={props.setScene}
        user={props.user}
        addStream={props.addStream}
        removeStream={props.removeStream}
        appsBeingUsed={props.appsBeingUsed}
        setAppsBeingUsed={props.setAppsBeingUsed}
        setIsShowingHelp={props.setIsShowingHelp}
        groupsData={groupsData}
        setHasMediaBeenFetched={setHasMediaBeenFetched}
        canBeDisplayed={canBeDisplayed}
      />

      <SelectorDevicesComponent
        deviceInstance={props.deviceInstance}
        localScreams={props.localScreams || []}
        mode={props.mode || "devices"}
        setScene={props.setScene}
        user={props.user}
        addStream={props.addStream}
        removeStream={props.removeStream}
        groupsData={groupsData}
        setHasDevicesBeenFetched={setHasDevicesBeenFetched}
        canBeDisplayed={canBeDisplayed}
      />
    </div>
  );
}
