// Modules
import { useEffect, useState } from 'react';
import axios from 'axios'
import { ToastContainer } from 'react-toastify';

// Components
import { DishComponent } from './components/dish/dish.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';


// Types
import { UserType } from './types/user.type';

// Styles
import './components/general.scss'
import './components/calendar.scss'
import './App.css';
import { UserSettingsHelper } from './helpers/UserSettingsHelper';
import { UserSettingsType } from './types/user_settings.type';
import { environment } from './environment';
import { Modal } from './components/modals/modal.component';
import { t } from 'i18next';
import { ForgotComponent } from './components/forgot/forgot.components';
import { RestoreComponent } from './components/restore/restore.components';
import { ChangePasswordComponent } from './components/changePassword/change-password.components';
import { HelpComponent } from './components/help/help.component';

// Axios Default Config Cors
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization';

/**
 * Sippar App
 * @returns {JSX.Element}
 */
function App() {

  // scene selected of the app
  const [scene, setScene] = useState<string | undefined>()

  // user logged
  const [user, setUser] = useState<UserType | undefined>()

  // room setted of videoconference
  const [room, setRoom] = useState<string | undefined>()

  const [userSettings, setUserSettings] = useState<UserSettingsType|undefined>()

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalType, setModalType] = useState<'success' | 'error' | 'warning'>('success');

  const [lastUsedHelp, setLastUsedHelp] = useState<string>('');

  const onClose = () => {
    setIsModalOpen(false)
    window.location.replace('/')
  }

  const [modalBody, setModalBody] = useState({
    title: '',
    content: ''
  });

  const confirmEmail = () => {
    const [controller, action, id] = window.location.pathname.split('/').filter((item) => item)

    if (controller === 'mails' && action === 'confirm' && id) {

      axios.get(`${environment.api.host}/api/mails/confirm/${id}`)
        .then((response) => {
          if (response) {
            setModalType('success')

            if (response && response.data && response.data.errors) {
              setModalType('warning')

              setModalBody({
                title: response.data.errors.email[0] || t('modal.success.email.title'),
                content: response.data.errors.email[1]
              })
            } else {
              setModalBody({
                title: t('modal.success.email.title'),
                content: t('modal.success.email.description_default')
              })

            }
          }

        })
        .catch((error) => {
          console.error(error)

          setModalType('error')

          if (error.response && error.response.data) {
            setModalBody({
              title: t('modal.error.email.title'),
              content: error.response.data.errors.email[0]
            })
          }
        }).finally(() => {
          setIsModalOpen(true)
        })
    }
  }

  const checkProviderLogin = () => {
    const [controller, action] = window.location.pathname.split('/').filter((item) => item)

    if (controller !== 'auth' || action !== 'callback') return

    const params = new URLSearchParams(window.location.search)

    if (!params.has('access_token')) return

    const access_token = params.get('access_token')

    // Get user profile and set user in localstorage
    axios
      .post(`${environment.api.host}/api/auth/my-profile`, null, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((response) => {
        const userResponse = response.data

        setUser({
          access_token,
          ...userResponse,
        })

        localStorage.setItem('user', JSON.stringify({
          access_token,
          ...userResponse,
        }))

        window.location.href = '/'
      })
      .catch((error) => {
        setModalType('warning')

        if (error.response && error.response.data) {
          setModalBody({
            title: t('modal.warning.title'),
            content: t('modal.warning.email.description_login')
          })
        }
      }).finally(() => {
        setIsModalOpen(true)

        setTimeout(() => {
          setIsModalOpen(false)
        }, 7000)
      });

  }

  const restorePassword = () => {
    const [controller, action, id] = window.location.pathname.split('/').filter((item) => item)

    if (controller === 'auth' && action === 'forgot' && id) {
      setScene('restore');
    }
  }

  useEffect(() => {

    // Check if user is logged in
    checkProviderLogin()

    // Read window path and check if need to restore password
    restorePassword()

    // Read window path and check if need to confirm email
    confirmEmail()

    try {

      // get user from localstorage
      const user = localStorage.getItem('user')
      if (user) setUser(JSON.parse(user))

      UserSettingsHelper.getUserSettings(user ? JSON.parse(user) : undefined)
      .then((userSettings) => {
        setUserSettings(userSettings)

        if (userSettings) {
          localStorage.setItem('user_settings', JSON.stringify(userSettings))
        }
      })
      .catch((error) => {
        console.error("[App] Error getting user settings", error);

        const userSettings = localStorage.getItem('user_settings')
        if (userSettings) setUserSettings(JSON.parse(userSettings) as UserSettingsType)
      });

    }
    catch (e) {

      // remove user from localstorage in corrupted JSON
      localStorage.removeItem('user')

    }

  }, [])


  return <div className="App">
    {
      // @ts-ignore
      window.electron && <div className='topbar'>

        <div className='icon' onClick={() => {

          // @ts-ignore
          window.electron.minimize()

        }}>
          <i className='las la-window-minimize' />
        </div>

        <div className='icon' onClick={() => {
          // @ts-ignore
          window.electron.close()
        }}>
          <i className='las la-times' />
        </div>

      </div>
    }
    {
      user && !user.user_setting.change_password ? <>
        <DishComponent
          room={room}
          setRoom={setRoom}
          setScene={setScene}
          scene={scene}
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          setUser={(user) => {
            if (!user) {
              setUser(undefined)
              localStorage.removeItem('user')
              localStorage.removeItem('user_settings')
              window.location.reload()
              setScene(undefined)
            } else {
              setUser(user)
              localStorage.setItem('user', JSON.stringify(user))
            }
          }}
          user={user}
        />
        {
          scene === 'directory' && <DirectoryComponent
            setScene={setScene}
            userSettings={userSettings}
            user={user}
          />
        }
        {
          scene === 'schedule' && <ScheduleComponent
            setRoom={setRoom}
            userSettings={userSettings}
            setScene={setScene}
            user={user}
          />
        }
        {
          scene === 'help' && <HelpComponent
            setScene={setScene}
            setLastUsedHelp={setLastUsedHelp}
            lastUsedHelp={lastUsedHelp}
          />
        }
      </> : <>

        <img
          src='/images/logo_comitas.png'
          alt='logo'
          className='Logotype'
        />
        {
          scene === 'register' ? <RegisterComponent
            setUser={setUser}
            setScene={setScene}
            onSuccess={(isAutoConfirm: boolean) => {
              setModalType('success')
              setModalBody({
                title: t('modal.register.success.title'),
                content: isAutoConfirm ? t('modal.register.success.auto_confirm') : t('modal.register.success.description')
              })
              setIsModalOpen(true)
            }}
          />
            : scene === 'forgot' ? <ForgotComponent
              setUser={setUser}
              setScene={setScene}
              onSuccess={() => {
                setModalType('success')
                setModalBody({
                  title: t('modal.forgot.success.title'),
                  content: t('modal.forgot.success.description')
                })
                setIsModalOpen(true)
              }}
            />
            : scene === 'restore' ? <RestoreComponent
              setUser={setUser}
              setScene={setScene}
              onSuccess={() => {
                setModalType('success')
                setModalBody({
                  title: t('modal.restore.success.title'),
                  content: t('modal.restore.success.description')
                })
                setIsModalOpen(true)
              }}
            />
            : scene === 'change-password' ? <ChangePasswordComponent
              user={user}
              setScene={setScene}
              onSuccess={() => {
                setModalType('success')
                setModalBody({
                  title: t('modal.change-password.success.title'),
                  content: t('modal.change-password.success.description')
                })
                setIsModalOpen(true)
              }}
            />
            : <LoginComponent
              setUser={setUser}
              setScene={setScene}
              setUserSettings={setUserSettings}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setModalType={setModalType}
              setModalBody={setModalBody}
            />
        }
      </>}

    <Modal isOpen={isModalOpen} onClose={onClose} type={modalType} body={modalBody}>

    </Modal>

    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      theme="light"
      progressClassName={'toast-progress'}
    />

  </div>

}

export default App;
