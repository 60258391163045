// Modules
import { useEffect, useState } from 'react';

// Components
import { ReactInternetSpeedMeter } from '../internet';
import { DishResize } from "../room/resize/dish.layout";

// Styles
import './controls.styles.scss';

// Properties
import { ControlsProperties } from './control.properties';
import { ControlsButtonType } from './controls.types';
import { useTranslation } from 'react-i18next';
import { UserSettingsHelper } from '../../../../helpers/UserSettingsHelper';

// Helpers
import { LocalStorageHelper } from '../../../../helpers/LocalStorageHelper';
import Modal from '../../../modals/modal.component';
import { use } from 'i18next';

/**
 * Controls Component
 * @description Component to render controls of videocall
 * @param {ControlsProperties} properties
 * @returns {JSX.Element}
 */
export function ControlComponent(properties: ControlsProperties): JSX.Element {

  // translation function
  const { t } = useTranslation();

  // toggle modes
  const [modesToggle, setModesToggle] = useState<boolean>(false)

  const [screenStream, setScreenStream] = useState<MediaStream | undefined>();

  const [modalOpen, setModalOpen] = useState(false);

  const [modalShareScreenOpen, setModalShareScreenOpen] = useState(false);

  const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(document.fullscreenElement ? true : false);

  const handleLogoutConfirm = () => {
    // remove user
    properties.setUser(undefined)

    // clean local storage
    LocalStorageHelper.cleanLocalStorage()

    // close modal
    setModalOpen(false)
  }

  const removeShareScreen = (stream: MediaStream) => {
    properties.removeShareScreen?.(stream);
    properties.socket.emit('stream:remove', {
      room: properties.room,
      layout: 'gallery',
      streamId: stream.id
    });

    setScreenStream(undefined);
  };

  // render button
  const renderButton = (settings: ControlsButtonType) => {

    // class names
    const classNames = [
      settings.className || 'button',
      settings.active ? 'active' : '',
      settings.mobile === false ? 'noMobile' : '',
    ].join(' ')

    const button = UserSettingsHelper.getUserSettingsButton(properties.userSettings, settings.name);

    if (button) {
      if (!button.visible) return;

      return <div title={settings.title} className={classNames} onClick={settings.onClick} >
        <i className={(settings.active ? button?.icons?.active : button?.icons?.inactive) || settings.icon} />
        {settings.count ? <sup
          className={button?.icons?.active === 'las la-terminal' ? 'red' : 'green'}
        >
          {settings.count} {
            settings.total && <span className='total'>/{settings.total}</span>
          }
        </sup> : null}
      </div>
    }

    return <div title={settings.title} className={classNames} onClick={settings.onClick} >
      <i className={settings.icon} />
      {settings.count ? <sup
        className={settings.icon === 'las la-terminal' ? 'red' : 'green'}
      >
        {settings.count} {
          settings.total && <span className='total'>/{settings.total}</span>
        }
      </sup> : null}
    </div>

  }


  return <div className={`ControlsComponent ${properties.isShowingHelp ? 'help' : ''}`}>
    <div className="buttons">
      <div className="first_group-buttons">
      {
        // NOTE(2024-08-20): Hide patient button for all users (Comitas requested)
        false && renderButton({
          name: 'menu.calendar',
          icon: 'las la-calendar',
          title: t('button.calendar.title').toString(),
          onClick: () => {

            // open schedule
            properties.setScene(properties.scene === 'schedule' ? undefined : 'schedule')

            // close modes
            setModesToggle(false)

            // close shareModal
            setModalShareScreenOpen(false);

          },
          active: properties.scene === 'schedule',
        })
      }
      {

        // NOTE(2024-07-04): Hide patient button for all users (Comitas requested)
        false && properties.userSettings?.is_doctor && renderButton({
          name: 'menu.notes',
          icon: 'las la-notes-medical',
          title: t('button.notes.title').toString(),
          onClick: () => {

            // open directory
            properties.setScene(properties.scene === 'directory' ? undefined : 'directory')

            // close modes
            setModesToggle(false)

            // close shareModal
            setModalShareScreenOpen(false);

          },
          active: properties.scene === 'directory',
        })
      }
      {
        // show button if is not in room
        !properties.room && renderButton({
          name: 'menu.internet',
          icon: 'las la-wifi',
          title: t('button.info.title').toString(),
          mobile: false,
          onClick: () => {

            // open settings
            properties.setScene(properties.scene === 'internet' ? undefined : 'internet')

            // close modes
            setModesToggle(false)

            // close shareModal
            setModalShareScreenOpen(false);

          },
          active: properties.scene === 'internet',
        })
      }
      {
        // show button if is not in room
        !properties.room && properties.clientMessage && properties.clientMessage !== '<p><br></p>' && renderButton({
          name: 'menu.info',
          icon: 'las la-info-circle',
          title: t('button.info.title').toString(),
          mobile: false,
          onClick: () => {

            // open settings
            properties.setScene(properties.scene === 'info' ? undefined : 'info')

            // close modes
            setModesToggle(false)

            // close shareModal
            setModalShareScreenOpen(false);

          },
          active: properties.scene === 'info',
        })
      }
      </div>
      {/* <span className='space'></span> */}
      <div className="second_group-buttons">
      {properties.room && <>
        {
          // @ts-ignore
          window.electron &&
          renderButton({ // Only show share button if electron is available
            name: 'menu.share',
            icon: 'las la-first-aid',
            title: t('button.share.title').toString(),
            mobile: false,
            onClick: () => {

              // open screen selector
              properties.setScene(properties.scene === 'selector-streams' ? undefined : 'selector-streams')

              // close modes
              setModesToggle(false)

              // close shareModal
              setModalShareScreenOpen(false);

            },
            active: properties.scene === 'selector-streams',
          })
        }

        {
          // @ts-ignore
          !window.electron && renderButton({
            name: 'menu.directShare',
            icon: 'las la-share-square',
            title: t('button.directShare.title').toString(),
            mobile: false,
            onClick: async() => {
              if (modalShareScreenOpen) {
                setModalShareScreenOpen(false);
                return;
              } else if (screenStream) {
                setModalShareScreenOpen(true);
              }

              if (!screenStream) {
                const stream = await properties.deviceInstance.getScreenMedias();

                if (stream) {
                  properties.addStream?.(stream, {type: 'share.screen'});
                  setScreenStream(stream);

                  stream.getTracks()[0].onended = () => {
                    removeShareScreen(stream);
                  };
                }
              }

              // resize dish
              new DishResize('DishScenary', 'DishScenary', 'DishVideoComponent').resize();

              // close selector
              properties.setScene?.(undefined);

              // close modes
              setModesToggle(false);
            },
            active: properties.scene === 'directShare' || !!screenStream,
          })
        }

        {
          properties.room &&
          !properties.localCall &&
          properties.numberParticipants > 0 &&
          renderButton({
            name: 'menu.share-file',
            icon: 'las la-file-upload',
            title: 'Compartir archivo',
            onClick: () => {
              console.log('Properties', properties.participants);

              properties.setScene(properties.scene === 'file' ? undefined : 'file')
              setModesToggle(false)
              setModalShareScreenOpen(false);
            },
          })
        }

        {
          (!properties.localCall || properties.answeredCall) && renderButton({
            name: 'menu.participants',
            icon: 'las la-user-friends',
            title: t('button.participants.title').toString(),
            onClick: () => {

              // open participants
              properties.setScene(properties.scene === 'participants' ? undefined : 'participants')

              // close modes
              setModesToggle(false)

              // close shareModal
              setModalShareScreenOpen(false);

            },
            active: properties.scene === 'participants',
            count: Object.keys(properties.participants).length,
          })
        }

        {
          renderButton({
            name: 'video.toggle',
            title: t('button.video.toggle.title').toString(),
            active: properties.videoStatus,
            icon: properties.videoStatus ? 'las la-video' : 'las la-video-slash',
            onClick: () => {
              properties.setVideoStatus && properties.setVideoStatus(!properties.videoStatus);
            }
          })
        }

        {
          renderButton({
            name: 'audio.toggle',
            title: t('button.video.audio.toggle.title').toString(),
            active: properties.audioStatus,
            icon: properties.audioStatus ? 'las la-microphone' : 'las la-microphone-slash',
            onClick: () => {
              properties.setAudioStatus && properties.setAudioStatus(!properties.audioStatus);
            }
          })
        }

        {
          (!properties.localCall || properties.answeredCall) && renderButton({
            name: 'menu.chat',
            icon: 'las la-comments',
            title: t('button.chat.title').toString(),
            onClick: () => {

              // clean last messages
              properties.setLastMessages([])

              // open chat
              properties.setScene(properties.scene === 'chat' ? undefined : 'chat')

              // close modes
              setModesToggle(false)

              // close shareModal
              setModalShareScreenOpen(false);

            },
            active: properties.scene === 'chat',
            count: properties.scene === 'chat' ? 0 : properties.lastMessages?.length || 0
          })
        }
      </>}
      </div>

      {/* <span className='space'></span> */}

      <div className="third_group-buttons">
      {
        properties.room && renderButton({
          name: 'menu.modes',
          icon: 'las la-th-large',
          title: t('button.modes.title').toString(),
          mobile: false,
          onClick: () => {
            // toggle modes
            setModesToggle(!modesToggle);

            // close shareModal
            setModalShareScreenOpen(false);

            // close scenes
            properties.setScene(undefined);

          },
          active: modesToggle,
        })
      }
      {/* {
        !properties.room && renderButton({
          name: 'menu.edit.profile',
          icon: 'las la-user-edit',
          title: t('button.edit.title').toString(),
          onClick: () => {

            // open edit profile
            properties.setScene(properties.scene === 'edit-profile' ? undefined : 'edit-profile')

          },
          active: properties.scene === 'edit-profile',
          className: 'button edit'
        })
      } */}
      {
        // @ts-ignore
        !window.electron && renderButton({
          name: 'menu.fullscreen',
          icon: fullscreenEnabled ? 'las la-compress' : 'las la-expand',
          title: t('button.fullscreen.title').toString(),
          mobile: false,
          onClick: () => {

            // close modes
            setModesToggle(false)

            // close shareModal
            setModalShareScreenOpen(false);

            // toggle fullscreen web
            if (!document.fullscreenElement) {

              // open fullscreen
              document?.documentElement?.requestFullscreen?.();

            } else {

              // close fullscreen
              document?.exitFullscreen?.();

            }

            setFullscreenEnabled(!fullscreenEnabled);

          }
        })
      }
      {
        properties.room && renderButton({
          name: 'menu.exit_room',
          icon: properties.numberParticipants > 0 ? 'las la-phone' : 'las la-home',
          title: properties.numberParticipants > 0 ? t('button.exit_call.title').toString() : t('button.exit_room.title').toString(),
          onClick: async () => {
            properties.setAnsweredCall(false);

            properties.socket.emit('call:cancelAll', {
                room: properties.room,
                users: properties.clickedUsers
              });

            properties.setClickedUsers([]);

            // emit exit to room
            properties.socket.emit('room:leave', {
              room: properties.room
            })

            // finish videocall, destroy and create new instance of socket
            properties.finishCall()

            // close modes
            setModesToggle(false)

            // close shareModal
            setModalShareScreenOpen(false);

            setScreenStream(undefined);
          },
          className: 'button exit close'
        })
      }
      {/* {
        !properties.room && renderButton({
          name: 'menu.logout',
          icon: 'las la-sign-out-alt',
          title: t('button.logout.title').toString(),
          onClick: () => { setModalOpen(true) },
          className: 'button close'
        })
      } */}
      </div>
      {
        modalShareScreenOpen &&
        <>
        <div className={`ShareScreenModal ${modalShareScreenOpen ? 'active' : 'inactive'}`}>
          <div className='content'>
            <div className='share-another' onClick={async () => {
              const stream = await properties.deviceInstance.getScreenMedias();

              if (stream) {

                screenStream && removeShareScreen(screenStream);
                properties.addStream?.(stream, {type: 'share.screen'});
                setScreenStream(stream);

                stream.getTracks()[0].onended = () => {
                  removeShareScreen(stream);
                };
              }

              setModalShareScreenOpen(false);

            }}>
              <i className="las la-images"></i>
                <p>{t('controls.share.another')}</p>
            </div>
            <div className='stop-sharing' onClick={() => {
              setModalShareScreenOpen(false);

              if (screenStream) {
                screenStream.getTracks().forEach((track) => {
                track.stop();
              });

              removeShareScreen(screenStream);
              }
            }}>
              <i className="las la-times-circle"></i>
              <p>{t('controls.share.stop')}</p>
            </div>
          </div>
        </div>
        </>
      }
      {
        properties.scene === 'internet' && <div className={
          `status ${properties.scene === 'internet' ? 'active' : 'inactive'}`
        }>
          {/* <div className='mode'>
            Socket <b>{properties.socket.status}</b>
          </div> */}
          <div className='mode'>
            {t('controls.speed')}
            {properties.scene === 'internet' && <ReactInternetSpeedMeter
              txtSubHeading="Internet is too slow"
              outputType="alert"
              customClassName={null}
              txtMainHeading="Opps..."
              pingInterval={1000} // Milliseconds
              thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
              threshold={100}
              imageUrl="https://comitas.es/wp-content/uploads/2023/04/Domus-VI-Jornada.png"
              downloadSize="115343"  // Bytes
              callbackFunctionOnNetworkDown={(speed: any) => console.log(`Internet speed is down ${speed}`)}
              callbackFunctionOnNetworkTest={(speed: any) => console.log(`Internet speed is down ${speed}`)}
            />}
          </div>
        </div>
      }
      {
        properties.scene === 'info' && <div className={
          `status ${properties.scene === 'info' ? 'active' : 'info'}`
        }>
          {
            properties.clientMessage &&
              <div className='message' dangerouslySetInnerHTML={ { __html: properties.clientMessage } } />
          }
        </div>
      }




      <Modal
        isOpen={properties.showLogout}
        onClose={() => properties.setShowLogout(false)}
        type='error'
        body={{
          title: t('modal.logout.title'),
          content: t('modal.logout.content'),
        }}
        buttons={[
          {
            text: t('modal.logout.cancel'),
            class: 'cancel',
            onClick: () => properties.setShowLogout(false)
          },
          {
            text: t('modal.logout.confirm'),
            class: 'confirm',
            onClick: handleLogoutConfirm
          },
        ]}
      ></Modal>

      {properties.room && <div className={`modes ${modesToggle && 'active'}`}>
        <div
          className={`mode ${properties.layout === 'gallery' ? 'active' : ''}`}
          onClick={() => properties.setLayout('gallery')}
        >
          <i className='las la-th' />
          {t('controls.gallery')}
        </div>
        <div
          className={`mode ${properties.layout === 'focus' ? 'active' : ''}`}
          onClick={() => properties.setLayout('focus')}
        >
          <i className='las la-th-large' />
          {t('controls.focus')}
        </div>
      </div>
      }
    </div>
  </div>;

}
