// Modules
import { toast } from "react-toastify"
import { Socket } from "socket.io-client"

// Types
import { UserType } from "../../../types/user.type"
import { AvailableType, SocketAvailableEventsInterface, } from "../dish.types"
import { SocketAskCloseSession, SocketUser } from "./socket.types";

/**
 * Socket Available
 * @description Socket to listen availables
 * @param {UserType} user
 * @param {Socket} socket
 * @param {SocketAvailableEventsInterface} events
 * @returns {void}
 */
export class SocketAvailable {

    // users availables
    availables: AvailableType[] = []

    constructor(properties: {
        user: UserType,
        socket: Socket,
        events: SocketAvailableEventsInterface
    }) {

        // emit hi to rest of users
        properties.socket.emit('say:hi', {
          user: { ...properties.user },
        })

        // listen welcome
        properties.socket.on('say:welcome', (data: { availables: SocketUser[] }) => {
          properties.events.onAvailables(data?.availables as AvailableType[])
        })

        properties.socket.on('call:outgoing', (_data: any) => {
          // NOTE: This is the event that is emitted when a user calls another user
          const fullName = `${_data.available.user.name}${_data.available.user.surnames ? ` ${_data.available.user.surnames}` : ''}`

          toast(`Llamando a ${fullName}`, _data)
        })

        // listen update availables
        properties.socket.on('availables:update', (data: { availables: SocketUser[] }) => {
          properties.events.onAvailables(data?.availables as AvailableType[])

        })

        // listen close session
        properties.socket.on('user:session:close', (data: SocketAskCloseSession) => {

          console.log(`User ${properties.socket.id} is closing session with ${data.user.id}`);
          console.log(data);

          if (data.ask) {
            properties.events.askCloseSession(data.ask)

            return;
          }

          properties.events.closeSession()
        })

        properties.socket.on('user:session:closed', (data: SocketAskCloseSession) => {
          properties.events.askCloseSession(data.ask)
        });

        // listen user accepted your call
        properties.socket.on('call:accepted', (data: any) => {
          const fullName = `${data.available.user.name}${data.available.user.surnames ? ` ${data.available.user.surnames}` : ''}`

          toast(`${fullName} ha aceptado la llamada`, data)

          properties.events.onAccepted(data)
        })

        // listen room created
        properties.socket.on('room:created', (data: any) => {

          toast("Reunión creada")
          properties.events.onRoom(data.room)

        })

        // listen call to you
        properties.socket.on('call:incoming', (data: any) => {
          properties.events.onCall(data)
        })

        // listen call rejected to you
        properties.socket.on('call:rejected', (data: any) => {
          const fullName = `${data.available.user.name}${data.available.user.surnames ? ` ${data.available.user.surnames}` : ''}`

          toast(`${fullName} ha rechazado la llamada`, data)
          properties.events.onRejected(data)

        })

        // listen call canceled
        properties.socket.on('call:canceled', (data: any) => {
          properties.events.onCanceled(data)
        })

        // change layout
        properties.socket.on('room:layout:change', (data: any) => {
          properties.events.onChangeLayout(data);
        })

        // remove stream
        properties.socket.on('stream:remove', (data: any) => {
          properties.events.onRemoveStream(data);
        })

        // change stream status
        properties.socket.on('stream:status_change', (data: any) => {
          properties.events.onChangeStreamStatus(data.streamId, data.status, data.type);
        })

        // Change stream track status
        properties.socket.on('stream:track:change_status', (data: {
          room?: string,
          streamId: string,
          streamType?: string,
          trackId: string,
          status: boolean,
          manual_id?: string,
          type: 'audio' | 'video'
        }) => {
          console.log('stream:track:change_status', data);

          properties.events.onChangeStreamTrackStatus(data.streamId, data.trackId, data.status, data.type, data.room, data.streamType, data.manual_id);
        })

        properties.socket.on('file:share', (data: any) => {
          properties.events.onFileShare(data.username, data.file, data.filename);
        })

        properties.socket.on('stream:change_volume', (data: any) => {
          properties.events.onChangeVolume(data.volume);
        })
    }
}
